<template>
    <router-view></router-view>
</template>

<script>
export default {
    name:'page-content',
    data(){
        return {

        }
    },
    mounted(){
    },
    computed:{
        
    }
}
</script>

<style lang="scss" scoped>
</style>